<template>
    <div class="solution-intellect-page">
        <div class="header">
            <p class="title">AI 智能虚拟人</p>
            <img class="sub-title-line" src="@/assets/img/solution/title-sub-line.png"/>
            <p class="desc">
                依托爱希游独创的智慧虚拟人系统，包括前沿的虚拟形象及口型生成、多端渲染、双模式驱动和深度学习等技术，
                并通过多模态SDK实现APP、智慧大屏一体机、PC、小程序、 H5、智能音箱、VR等多种终 端展现形式，
                为用户提供接近真人的互动、咨询、讲解、客服、导览等服务， 常用于政务、接待、智慧助理、文旅以及24小时直播带货场景。
            </p>
            <img class="line-img" src="@/assets/img/solution/desc-sub-line.png"/>
            <img class="header-right-img" src="@/assets/img/solution/intellect/shopping.png"/>
        </div>

        <div class="section-box" style="margin-top: 4.8rem;">
            <section-title title="技术原理"></section-title>
            <img class="content-img" style="margin-top: 0.33rem;" src="@/assets/img/solution/intellect/technolegy.png"/>
        </div>
        <div class="section-box">
            <section-title title="方案架构"></section-title>
            <img class="content-img"  style="margin-top: 0.99rem;" src="@/assets/img/solution/intellect/fajg.png"/>
        </div>

        <div class="section-box">
            <section-title title="方案优势"></section-title>
            <p class="icon-box"><img class="user-img" src="@/assets/img/solution/intellect/user.png"/></p>
            <div class="top-box">
                <div class="top-list" v-for="(e, index) in topList" :key="index">
                    <img :src="e.icon"/>
                    <div class="content-box">
                        <p class="title">{{ e.title }}</p>
                        <p class="desc">{{ e.desc }}</p>
                    </div>
                </div>
            </div>
        </div>

        <div class="section-box">
            <section-title title="商业价值"></section-title>
            <div class="line"></div>
            <el-row class="business-box" :gutter="20">
                <el-col class="left-box" :span="13">
                    <div class="left-item" v-for="(e, index) in businessList" :key="index">
                        <p class="title">{{ e.title }}</p>
                        <p class="desc">{{ e.desc }}</p>
                    </div>
                </el-col>
                <el-col :span="11">
                    <img src="@/assets/img/solution/intellect/intellect-right.png"/>
                </el-col>
            </el-row>
        </div>
        <div class="section-box">
            <section-title title="应用场景"></section-title>
            <ul class="item-list">
                <li v-for="h in sceneList" :key="h.key">
                    <img :src="h.image"/>
                </li>
            </ul>
        </div>
				<ContactInformation style="margin-top: 1.8rem"></ContactInformation>
				
    </div>
</template>
<script>
export default {
    name: 'Intellect',
    data() {
        return {
            sceneList: [
                {
                    key: 'intellect-scene-1',
                    image: require("@/assets/img/solution/intellect/intellect-scene-1.png"),
                },
                {
                    key: 'intellect-scene-2',
                    image: require("@/assets/img/solution/intellect/intellect-scene-2.png"),
                },
                {
                    key: 'intellect-scene-3',
                    image: require("@/assets/img/solution/intellect/intellect-scene-3.png"),
                },
                {
                    key: 'intellect-scene-4',
                    image: require("@/assets/img/solution/intellect/intellect-scene-4.png"),
                },
                {
                    key: 'intellect-scene-5',
                    image: require("@/assets/img/solution/intellect/intellect-scene-5.png"),
                }
            ],
            businessList: [
                {
                    title: '提升用户体验和转化',
                    desc: '通过可视化的、无限接近真人的虚拟人交互，为用户提供定制化互动体验，还可以设置更多有趣的互动方式', 
                },
                {
                    title: '升级客服体系',
                    desc: '提供7x24小时互动、咨询、讲解、导览等客户服务， 响应快速，即问即答，助力企业客服体系智能化升级', 
                },
                {
                    title: '助力营销创新',
                    desc: '借助品牌IP形象，开展线上线下创意互动营销，推动品牌营销获客', 
                },
                {
                    title: '降低运营成本，提升闲时流量',
                    desc: '虚拟助手可替代真人，帮助企业解决90%的日常客服问题， 对于直播带货场景，可与真人交替，尤其是抢占夜间流量。', 
                },
            ],
            topList: [
                {
                    icon: require("@/assets/img/solution/realastic.png"),
                    title: '主流语音兼容',
                    desc: '完美适配客户原有的语言合成能力(TTS)、语音识别能 力(ASR)、自然语言处理能力(NLP);并支持将文字语音对话转变成虚拟形象的问答模式', 
                },
                {
                    icon: require("@/assets/img/solution/realastic.png"),
                    title: '形象风格定制',
                    desc: '打造量身定做的AI虚拟形象，支持3D卡通人、3D动物、 2D真人，深度学习真人风格等满足各个场景的定制化需求', 
                },
                {
                    icon: require("@/assets/img/solution/realastic.png"),
                    title: '多客户端适配',
                    desc: '支持云端或本地渲染，兼容小程序、H5页面、APP、智慧大屏、车载、移动端及 windows 平台，提供使用范围广的专业级虚拟人', 
                },
                {
                    icon: require("@/assets/img/solution/realastic.png"),
                    title: '口唇同步,表情动作丰富',
                    desc: '根据问答内容实时生成口唇效果，实现语音驱动口唇同步一致，驱动虚拟形象多模态交互', 
                },
                {
                    icon: require("@/assets/img/solution/realastic.png"),
                    title: '标签设置灵活',
                    desc: '可个性化配置语音问答中的情绪和动作，针对问答场景，打造丰富精准的表达能力', 
                },
                {
                    icon: require("@/assets/img/solution/realastic.png"),
                    title: '标准版快速配置',
                    desc: '可以快速打造专属直播间，自定义环境和形象，标准版既拥有丰富有趣的互动功能，支持商品自动匹配弹出，支持用户点播等。', 
                }
            ]
        }
    }
}
</script>
<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";
@import "@/assets/scss/mixin.scss";
@mixin item-list {
    display: flex;
    justify-content: space-between;
    li {
        width: 2.8rem;
        img {
            width: 100%;
        }
    }
}

.line {
    width: 100%;
    height: 0.18rem;
    margin-top: 0.53rem;
    background: url(../../assets/img/solution/line-bg.png) no-repeat;
    background-size: 100% 100%;
}

    .solution-intellect-page {
			height: 100%;
			overflow-y: scroll;
        .header {
            position: relative;
            margin-top: 1.24rem;
            margin-left: $whole-lr;
            .title {
                @include font-2;
            }
            .sub-title-line {
                height: 0.22rem;
                margin-top: 0.15rem;
            }
            .desc {
                margin-top: 0.49rem;
                width: 47%;
                @include de-0-16;
            }
            .line-img {
                width: 47%;
                height: 0.87rem;
                margin-top: 0.34rem;
            }
            .header-right-img {
                position: absolute;
                width: 7.29rem;
                right: 1.26rem;
                top: 2.8rem;

            }
        }
        .section-box {
            position: relative;
            margin-left: $whole-lr;
            margin-right: $whole-lr;
            margin-top: 1.8rem;

            .content-img {
                width: 100%;
            }
            .icon-box {
                text-align: center;
                .user-img {
                    width: 5.28rem;
                    height: 6.09rem;
                }
            }
            .top-box {
                width: 100%;
                margin-top: 1rem;
                display: flex;
                flex-wrap: wrap;

                .top-list {
                    width: 50%;
                    margin-bottom: 0.67rem;
                    display: flex;

                    .content-box {
                        margin-left: 0.32rem;
                        .title {
                            @include font-section;
                            font-weight: bold;
                        }
                        .desc {
                            @include de-0-14;
                            margin-top: 0.12rem;
                            width: 90%;
                        }
                    }
                    
                    >img {
                        width: 0.78rem;
                        margin-top: 0;
                    }
                }

            }

            .business-box {
                margin-top: 1.08rem;
                display: flex;
                align-items: center;
                .left-box {
                    .left-item {
                        margin-bottom: 0.54rem;
                        .title {
                            @include font-section;
                            font-weight: bold;
                        }
                        .desc {
                            width: 90%;
                            margin-top: 0.16rem;
                            @include de-0-16;
                        }

                    }
                }

                img {
                        width: 100%;
                    }

            }
            .item-list {
                margin-top: 0.83rem;
                @include item-list;

            }

            
        }
    }
</style>