<template>
    <div class="digital-page">
        <header>
            <img width="100%" src="@/assets/img/solution/digital/header.png"/>
            <div class="header-box">
                <p class="title">数字分身虚拟人</p>
                <img class="sub-title-line" src="@/assets/img/solution/title-sub-line.png"/>
                <div class="desc">
                    <p>深度学习1: 1复刻真人音容相貌，主要用于播报视频生产，还可用于情感对话、交互设计、</p>
                    <p>诗歌创作、绘画创作、音乐创作等</p>
                </div>
            </div>
            <ul class="item-list">
                <li v-for="h in sceneList" :key="h.key">
                    <img class="left-img" :src="h.icon"/>
                    <div class="right-box">
                        <p class="title">{{ h.title }}</p>
                        <p class="desc">{{ h.desc }}</p>
                    </div>
                </li>
            </ul>
        </header>
        <div class="section-box">
            <section-title class="se-mg-l" title="制作流程"></section-title>
            <ul class="se-mg-l create-flow-box">
                <li class="li-item" v-for="(c, index) in flowList" :key="index" :style="{'width': `${c.width}`, 'margin-left': c.left, 'margin-top': c.top}">
                    <p class="title">{{ c.title }}</p>
                    <p class="desc">{{ c.desc }}</p>
                    <img width="100%" :src="c.icon"/>
                </li>
            </ul>
        </div>

        <div class="section-box">
            <section-title class="se-mg-l" title="方案架构"></section-title>
            <p class="sub-desc se-mg-l">
                提供丰富的人工智能生产工具，包括播报视频制作、交互内容搭建、虚拟直播管理、
                艺术创作、情感关怀，可灵活对接外部系统，应用在海量软/硬终端，内容可输出至各类平台，
                能够胜任客服、销售、文旅、娱乐、教育等海量场景。
            </p>
            <div class="content-box">
                <img src="@/assets/img/solution/digital/fajg.png"/>
            </div>
        </div>
        
        <ContactInformation style="margin-top: 1.8rem"></ContactInformation>
        

    </div>
</template>
<script>
export default {
    name: 'Digital',
    data() {
        return {
            sceneList: [
                {
                    icon: require("@/assets/img/solution/digital/appearance.png"),
                    title: '形 象',
                    desc: '通过深度神经网络复刻真人形象', 
                },
                {
                    icon: require("@/assets/img/solution/digital/voice.png"),
                    title: '声 音',
                    desc: '超自然语音技术，在声音中加入“瑕疵”让声音 更加真实自然。', 
                },
                {
                    icon: require("@/assets/img/solution/digital/interactive.png"),
                    title: '交 互',
                    desc: '通过低代码灵活配置数字员工交互逻辑灵活对接外部知识库和系统。', 
                },
                {
                    icon: require("@/assets/img/solution/digital/create.png"),
                    title: '创 作',
                    desc: '人工智能诗歌、绘画、音乐创作模型', 
                },
                {
                    icon: require("@/assets/img/solution/digital/chat.png"),
                    title: '闲 聊',
                    desc: '全球领先的开放域对话引擎', 
                },
                {
                    icon: require("@/assets/img/solution/digital/broadcast.png"),
                    title: '播 报',
                    desc: '输入文字，平台生成数字员工口播视频', 
                },

            ],
            flowList: [
                {
                    icon: require("@/assets/img/solution/digital/line-1.png"),
                    title: '视频素材录制',
                    desc: '30 min 素材', 
                    width: '33%',
                    left: '0',
                    top: '0.36rem'
                },
                {
                    icon: require("@/assets/img/solution/digital/line-2.png"),
                    title: '云端模型训练',
                    desc: '训练 1 周', 
                    width: '33%',
                    left: '33%',
                    top: '0.35rem'
                },
                {
                    icon: require("@/assets/img/solution/digital/line-3.png"),
                    title: '超自然语言定制',
                    desc: '区别于传统TTS，在声音中加入了“瑕疵”，大幅降低机器语音中的机械感，让数字员工的声音更加贴近真实人类', 
                    width: '100%',
                    left: '0',
                    top: '0.72rem'
                },
                {
                    icon: require("@/assets/img/solution/digital/line-4.png"),
                    title: '视频内容生成',
                    desc: '内容生产平台围绕数字员工口播视频生产，为用户提供丰富的视频制作功能，包括定制模板、导演模式、云剪辑、素材管理、群组管理等', 
                    width: '100%',
                    top: '0.37rem'
                },
                {
                    icon: require("@/assets/img/solution/digital/line-5.png"),
                    title: '人机对话设计',
                    desc: '通过低代码的方式构建数字员工的“大脑”，可根据不同行业应用场景灵活自由的设计回复话术，可对接TTS/ASR等外部模块实现语音、视觉交互，亦可对接知识库、外部服务，从而让数字员工具备更多的技能。', 
                    width: '100%',
                    top: '0.37rem'
                },
            ]
            
        }
    }
}
</script>
<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";
@import "@/assets/scss/mixin.scss";

// @mixin desc {
//     line-height: 2rem;
//     font-size: 1.13rem;
//     color: rgba($color: white, $alpha: 0.8);
// }
// @mixin title {
//     color: #F5F5FF;
//     font-size: 1.5rem;
// }
:root {
    
}

    .digital-page {
			height: 100%;
			overflow-y: scroll;
        header {
            .header-box {
                margin-left: $whole-lr;
                margin-top: 1.6rem;
                .title {
                    @include font-2;
                    letter-spacing: 10px;
                }
                .sub-title-line {
                    height: 0.25rem;
                    margin-top: 0.16rem;
                }
                .desc {
                    @include de-0-18;
                    letter-spacing: 3px;
                    margin-top: 0.36rem;
                }

            }
            .item-list {
                margin-left: $whole-lr;
                margin-right: $whole-lr;
                margin-top: 1.75rem;
                display: flex;
                flex-wrap: wrap;
                li {
                    width: 33%;
                    display: flex;
                    align-items: center;
                    margin-bottom: 0.82rem;
                    .left-img {
                        width: 1.64rem;
                        height: 1.64rem;
                    }
                    .right-box {
                        margin-left: 0.3rem;

                        .title {
                            @include font-3;
                        }
                        .desc {
                            @include de-0-16;
                            margin-top: 0.12rem;
                            margin-right: 0.3rem;
                        }

                    }
                }
                
            }
            
        }
        .section-box {
            margin-top: 1.78rem;
            .create-flow-box {
                margin-top: 0.85rem;
                margin-right: 2.15rem;
                background: url('../../assets/img/solution/digital/dashed-line.png') no-repeat;
                background-size: 100% 100%;
                padding-top: 0.42rem;
                padding-bottom: 0.42rem;

                .li-item {
                    padding-bottom: 0.32rem;

                    .title {
                        @include font-4;
                        font-weight: bold;

                    }
                    .desc {
                        margin-top: 0.12rem;
                        @include de-0-18;
                    }
                    img {
                        margin-top: 0.38rem;
                        height: 0.55rem;
                    }
                    

                }
            }

            .sub-desc {
                margin-right: $whole-lr;
                margin-top: 0.32rem;

                @include de-0-18;
            }
            .content-box {
                margin-right: $whole-lr;
                margin-left: $whole-lr;
                margin-top: 0.93rem;

                img {
                    margin-bottom: 1.8rem;
                    width: 100%;
                }
            }
        }
        

    }
</style>