<template>
    <div class="universe-page" id="mainContainer">
        <div class="header">
            <img width="100%" src="@/assets/img/solution/meta/header.png"/>
            <div class="header-box">
                <p class="title">元宇宙解决方案</p>
                <img class="sub-title-line" src="@/assets/img/solution/title-sub-line.png"/>
                <div class="desc sec-mg-t">
                    <p>元宇宙空间服务集合，打通一体化服务</p>
                </div>
            </div>
            <ul>
                <li v-for="h in headerList" :key="h.title">
                    <div class="title-box">
                        <p>{{ h.title }}</p>
                    </div>
                    <p class="desc">{{ h.desc }}</p>
                </li>
            </ul>
        </div>
        <div class="section-box se-mg-t">
            <section-title class="se-mg-l" title="场景示例"></section-title>
            <ul class="se-mg-l scene-box">
                <li class="li-item" v-for="(c, index) in sceneList" :key="index" @click="exchangeScene(c, index)">
                    <div class="img-content" :class="index == currentIndex ? 'active-img' : 'unactive-img'">
                        <img :src="c.icon"/>
                    </div>
                    <p class="title">{{ c.title }}</p>
                </li>
            </ul>
        </div>
        <div class="detail-box" id="detail">
            <div class="se-mg-t">
                <img v-if="currentIndex == 0" width="100%" src="@/assets/img/solution/meta/ywcj-detail.png"/>
                
                <div v-if="currentIndex == 1" class="v-box">
                    <p class="sub-title">A角色：业务员虚拟人</p>
                    <img class="a-role" src="@/assets/img/solution/meta/1v1/a-role.png"/>
                    <p class="gradient-line"></p>
                    <p class="sub-title">B角色：用户虚拟人</p>
                    <el-row :gutter="20">
                        <el-col class="item-img" v-for="b in 4" :key="b" :span="6">
                            <img :src="require(`@/assets/img/solution/meta/1v1/b-role-${b}.png`)"/>
                        </el-col>
                    </el-row>
                    <p class="gradient-line"></p>
                    <div class="footer-box">
                        <img class="center-role" src="@/assets/img/solution/meta/1v1/center-role.png"/>
                        <el-row style="margin-bottom: 1.77rem">
                            <el-col v-for="l in 6" :span="4" :key="l">
                                <img :src="require(`@/assets/img/solution/meta/1v1/label-${l}.png`)"/>
                            </el-col>
                        </el-row>
                    </div>
                </div>

                <div class="bdzb-box" v-if="currentIndex == 2">
                    <p class="detail-desc">• 不同品牌招商入驻元宇宙空间并配备入驻后台</p>
                    <p class="detail-desc">• 模板化快速装修一键开启店铺经营【积分兑换/优惠购买】</p>
                    <div class="line"></div>
                    <el-row class="business-box" :gutter="20">
                        <el-col :span="14" class="left-box">
                            <div v-for="(e, index) in topList" :key="index">
                                <img :src="e.icon"/>
                                <span class="title">{{ e.title }}</span>
                            </div>
                        </el-col>
                        <el-col :span="10">
                            <img width="100%" src="@/assets/img/solution/meta/bdshzb-detail.png"/>
                        </el-col>
                    </el-row>
                </div>
                <img v-if="currentIndex == 3" width="100%" src="@/assets/img/solution/meta/cqszt-detail.png"/>
                <div v-if="currentIndex == 4">
                    <img width="100%" src="@/assets/img/solution/meta/zjzt-detail.png"/>
                </div>

                <div v-if="currentIndex == 5">
                    <el-row :gutter="20">
                        <ul class="item-list">
                            <li v-for="p in 4" :key="p">
                                <img :src="require(`@/assets/img/solution/meta/p-${p}.png`)"/>
                            </li>
                        </ul>
                        <el-col v-for="p in 4" :key="p" :span="6">
                        </el-col>
                    </el-row>
                </div>
            </div>
        </div>
				<ContactInformation style="margin-top: 1.8rem"></ContactInformation>
				
    </div>
</template>
<script>
export default {
    name: 'Digital',
    data() {
        return {

            headerList: [
                {
                    icon: require("@/assets/img/solution/meta/outer-border.png"),
                    title: '感官沉浸性',
                    desc: '“具身的临场感”以第一视角感受环境', 
                },
                {
                    icon: require("@/assets/img/solution/meta/outer-border.png"),
                    title: '品牌服务性',
                    desc: '以数字化身Avatar提供业务服务', 
                },
                {
                    icon: require("@/assets/img/solution/meta/outer-border.png"),
                    title: '交互开放性',
                    desc: '引入2B合作，丰富用户生活消费', 
                }
            ],
            topList: [
            {
                    icon: require("@/assets/img/solution/realastic.png"),
                    title: '模块化装修3D店铺',
                    desc: '', 
                },
                {
                    icon: require("@/assets/img/solution/realastic.png"),
                    title: '兴趣商品快速推销',
                    desc: '', 
                },
                {
                    icon: require("@/assets/img/solution/realastic.png"),
                    title: '商品SKU智能解说',
                    desc: '', 
                }
            ],
            currentIndex: 0,
            sceneList: [
                {
                    key: 'ywcj',
                    icon: require("@/assets/img/solution/meta/ywcj.png"),
                    title: '业务场景'                  
                },
                {
                    key: '1v1hkt',
                    icon: require("@/assets/img/solution/meta/1v1hkt.png"),
                    title: '1V1会客厅'                   
                },
                {
                    key: 'bdshzb',
                    icon: require("@/assets/img/solution/meta/bdshzb.png"),
                    title: '本地生活店铺直播',
                },
                {
                    key: 'cqszt',
                    icon: require("@/assets/img/solution/meta/cqszt.png"),
                    title: '沉浸式展厅'                    
                },
                {
                    key: 'zjzt',
                    icon: require("@/assets/img/solution/meta/zjzt.png"),
                    title: '专家座谈/培训'                   
                },
                {
                    key: '',
                    icon: require("@/assets/img/solution/meta/ppyxhd.png"),
                    title: '品牌营销活动'                    
                }
            ]
            
        }
    },
    methods: {
        exchangeScene(c, index) {
            this.currentIndex = index;
            // console.log(document.getElementById('detail').offsetTop);
            // console.log(document.getElementById('mainContainer').offsetTop);
            const detail = document.getElementById('detail');
            const mainContainer = document.getElementById('solution');
            console.log(mainContainer.style.paddingTop);

            document.getElementById('mainContainer').scroll({
                left: 0,
                top: detail.offsetTop - 100,
                behavior: 'smooth'
            })
        }
    }
}
</script>
<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";
@import "@/assets/scss/mixin.scss";
// @mixin desc {
//     line-height: 2rem;
//     font-size: 1.13rem;
//     color: rgba($color: white, $alpha: 0.8);
// }
// @mixin title {
//     color: #F5F5FF;
//     font-size: 1.5rem;
// }

@mixin line {
    width: 100%;
    height: 0.5rem;
    background: url(../../assets/img/solution/line-bg.png) no-repeat;
    background-size: 100% 100%;
}

.sub-title-line {
    height: 0.25rem;
    width: 6.32rem;
    margin-top: 0.15rem;
}

.item-list {
    
    display: flex;
    justify-content: space-between;
    li {
        width: 23%;
        img {
            width: 100%;
        }
    }
}


    .universe-page {
        height: 100%;
        overflow-y: scroll;
        .header {
            position: relative;
            .header-box {
                position: absolute;
                left:  1.24rem;
                top: $whole-lr;

                .title {
                    @include font-2;
                    letter-spacing: 10px;
                }
            
                .desc {
                    margin-top: 0.49rem;
                    @include de-0-18;
                    letter-spacing: 3px;
                }
            }
            ul {
                display: flex;
                text-align: center;
                li {
                    width: 33%;
                    display: flex;
                    align-items: center;
                    flex-direction: column;

                    .title-box {
                        background: url(../../assets/img/solution/meta/outer-border.png) no-repeat;
                        background-size: 100% 100%;
                        width: 3.32rem;
                        height: 3.72rem;

                        p {
                            margin-top: 1.73rem;
                            @include font-section;
                            font-size: 0.2rem;
                        } 
                    }
                    .desc {
                        @include de-0-16;
                    }
                }
            }

        }
        .section-box {

            .scene-box {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                margin-top: 0.7rem;
                margin-right: $whole-lr;

                .li-item {
                    width: 30%;
                    cursor: pointer;
                    margin-bottom: 0.8rem;

                    .img-content {
                        position: relative;
                        overflow: hidden;
                        
                        img {
                            width: 100%;
                            transition: transform .3s;
                        }
                    }
                    .img-content:hover img {
                        // transform: scale(1.1);
                    }
                    .unactive-img::after {
                        content: '';
                        position: absolute;
                        left: 0;
                        top: 0;
                        right: 0;
                        bottom: 0;
                        background: rgba($color: #000, $alpha: 0.68);
                        transition: transform .3s;
                        transform: translate(0, 0);
                    }
                    // .unactive-img:hover::after {
                    //     transform: translate(0, 100%);
                    // }

                    .title {
                        margin-top: 0.35rem;
                        text-align: center;
                        letter-spacing: 1px;
                        @include font-section;
                    }
                }
            }
        }
        .detail-box {
            margin-left: $whole-lr;
            margin-right: $whole-lr;
            padding-bottom: 1.8rem;

            .v-box {
                border:1px solid transparent;
                background: url(../../assets/img/solution/meta/1v1/detail-bg.png) no-repeat;
                background-size: 100% 100%;
                padding-left: 0.66rem;
                padding-right: 0.66rem;

                .sub-title {
                    margin-top: 1.13rem;
                    @include font-section;
                    font-size: 0.28rem;
                    letter-spacing: 5px;
                }
                .a-role {
                    width: 100%;
                    margin-top: 0.8rem;
                }

                
                .gradient-line {
                    margin-top: 0.8rem;
                    width: 100%;
                    height: 0.01rem;
                    border: 0.01rem solid;
                    border-image: linear-gradient(223deg, rgba(47, 52, 151, 0.3), rgba(110, 101, 194, 1), rgba(139, 120, 197, 1), rgba(56, 61, 178, 0.2)) 0.5 0.5;
                }

                .item-img {
                    margin-top: 1rem;
                    text-align: center;
                    img {
                        max-width: 50%;
                        height: 5rem;

                    }
                }

                .footer-box {
                    text-align: center;
                    .center-role {
                        margin: 1.19rem auto;
                        width: 5.28rem;
                        height: 6.08rem;
                    }
                }
            }
        }

        .bdzb-box {
            position: relative;
            margin-bottom: 0.84rem;

            .detail-desc {
                @include de-0-18;
                letter-spacing: 3px;
            }
            .line {
                @include line;
                margin-top: 0.45rem;
            }

            .business-box {
                margin-top: 0.44rem;
                display: flex;
                align-items: center;

                .left-box {

                    div {
                        display: flex;
                        align-items: center;
                        margin-bottom: 0.64rem;
                        
                        img {
                            width: 0.78rem;
                        }

                        .title {
                            margin-left: 0.32rem;
                            letter-spacing: 1px;
                            @include font-section;
                            font-weight: bold;
                        }
                        // :nth-child(n+2) {
                        //     margin-top: 0.9rem;
                        // }
                    }
                    
                }

                
            }
            
            
        }
        
        

    }
</style>