<template>
    <div class="solution-virtual-page">
        <div class="header">
            <p class="title">虚拟人内容创作</p>
            <img class="sub-title-line" src="@/assets/img/solution/title-sub-line.png"/>
            <p class="desc">
                非交互型虚拟人主要运营内容不以实时交互为目的，主要内容类型为各类传统的长中短虚拟内容，
                        如：影视动画、游戏、虚拟综艺、MV、短视频、图片等。
            </p>
            <img class="line-img" src="@/assets/img/solution/desc-sub-line.png"/>
            <img class="header-right-img" src="@/assets/img/solution/virtual/header-right.png"/>
        </div>

        <div class="section-box" style="margin-left: 0; margin-right: 0; margin-top: 5.3rem;">
            <section-title class="se-mg-l" title="标准流程"></section-title>
            <div class="flow-content">
                <div class="title se-mg-l">
                    <p>实现虚拟人非交互内容</p>
                    <p>制作标准化</p>
                    <img style="width: 8rem;" class="sub-title-line" src="@/assets/img/solution/title-sub-line.png"/>
                </div>
                <p class="desc se-mg-l" style="width: 100%;">
                    流程工业化，QC标准化， 流程自动化，协同云端化 
                </p>
            </div>
            <img style="margin-top: 0.77rem" width="100%" src="@/assets/img/solution/virtual/flow-bg.png"/>
        </div>

        <div class="section-box">
            <section-title title="方案优势"></section-title>
            <div class="top-box">
                <div class="top-list" v-for="(e, index) in topList" :key="index">
                    <img :src="e.icon"/>
                    <div class="content-box">
                        <p class="title">{{ e.title }}</p>
                        <p class="desc">{{ e.desc }}</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="section-box" style="margin-top: 2.2rem;">
            <section-title title="商业应用"></section-title>
            <div class="line"></div>
            <ul class="item-list">
                <li style="width: 30%" v-for="h in sceneList" :key="h.key">
                    <img :src="h.image"/>
                </li>
            </ul>
        </div>
				<ContactInformation style="margin-top: 1.8rem"></ContactInformation>
				
    </div>
</template>
<script>
export default {
    name: 'Broadcast',
    data() {
        return {
            sceneList: [
                {
                    key: 'apply-1',
                    image: require("@/assets/img/solution/virtual/apply-1.png"),
                },
                {
                    key: 'apply-2',
                    image: require("@/assets/img/solution/virtual/apply-2.png"),
                },
                {
                    key: 'apply-3',
                    image: require("@/assets/img/solution/virtual/apply-3.png"),
                },
            ],
            businessList: [
                {
                    title: '高质量内容生成系统，助力视频内容创新',
                    desc: '语音动画合成技术和人工智能技术业界领先，虚拟人播报媲美真人，助力企业打造个性化的视频营销内容', 
                },
                {
                    title: '一键生成，提升内容生产效率',
                    desc: '无需场地、无需人员，即可简单快捷的制作视频，保障播报实时性、零失误，有效降低制作成本，缩短视频制作周期，提高制播效率', 
                },
            ],
            topList: [
                {
                    icon: require("@/assets/img/solution/realastic.png"),
                    title: '制作效率提升',
                    desc: '通过AI、图形学技术实现多环节智能化制作，显著提升虚拟内容制作效率', 
                },
                {
                    icon: require("@/assets/img/solution/realastic.png"),
                    title: '创作质量优化',
                    desc: '智能化、工业化的生产模式，保障高质量稳定产出', 
                },
                {
                    icon: require("@/assets/img/solution/realastic.png"),
                    title: '产能规模提升',
                    desc: '工业化、云端协同实现规模化高质量产能，提供元宇宙世代稀缺资源'
                }
            ]
        }
    }
}
</script>
<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";
@import "@/assets/scss/mixin.scss";
@mixin item-list {
    display: flex;
    justify-content: space-between;
    li {
        width: 2.8rem;
        img {
            width: 100%;
        }
    }
}

.sub-title-line {
    height: 0.25rem;
    margin-top: 0.15rem;
}

.line {
    width: 100%;
    height: 0.18rem;
    margin-top: 0.53rem;
    background: url(../../assets/img/solution/line-bg.png) no-repeat;
    background-size: 100% 100%;
}

    .solution-virtual-page {
			height: 100%;
			overflow-y: scroll;
        .header {
            position: relative;
            margin-top: 1.24rem;
            margin-left: $whole-lr;
            .title {
                @include font-2;
            }
           
            .desc {
                margin-top: 0.49rem;
                width: 47%;
                @include de-0-16;
            }
            .line-img {
                width: 47%;
                height: 0.87rem;
                margin-top: 0.54rem;
            }
            .header-right-img {
                position: absolute;
                width: 5.28rem;
                height: 6.08rem;
                right: 1.8rem;
                top: 1.4rem;

            }
        }
        .section-box {
            position: relative;
            margin-left: $whole-lr;
            margin-right: $whole-lr;
            margin-top: 1.8rem;

            .top-box {
                width: 100%;
                margin-top: 1.2rem;
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;

                .top-list {
                    width: 48%;
                    margin-bottom: 0.67rem;
                    display: flex;

                    .content-box {
                        margin-left: 0.32rem;
                        .title {
                            @include font-section;
                            font-weight: bold;
                        }
                        .desc {
                            @include de-0-14;
                            margin-top: 0.12rem;
                        }
                    }
                    
                    >img {
                        width: 0.78rem;
                        margin-top: 0;
                    }
                }

            }

            .flow-content {
                position: absolute;
                border: 1px solid transparent;
                z-index: 4;
                top: 2.83rem;
                .title {
                    
                    @include font-2;
                }
                .desc {
                    margin-top: 0.5rem;
                    @include de-0-18;
                }
            }

            .item-list {
                margin-top: 1.04rem;
                @include item-list;

            }

            
        }
    }
</style>