<template>
    <div class="solution-page"> 
		<div id="solution">
			<human v-if="type == 'human'"></human>
			<intellect v-if="type == 'intellect'"></intellect>
			<Broadcast v-if="type == 'broadcast'"></Broadcast>
			<Virtual v-if="type == 'virtual'"></Virtual>
			<digital v-if="type == 'digital'"></digital>
			<Universe v-if="type == 'meta'"></Universe>
		</div>
    </div>
</template>
<script>
import Human from './human.vue';
import Intellect from './intellect.vue';
import Broadcast from './broadcast.vue';
import Virtual from './virtual.vue';
import Digital from './digital.vue';
import Universe from './universe.vue';

export default {
    name: 'Solution',
    components: {
        Human,
        Intellect,
        Broadcast,
        Virtual,
        Digital,
        Universe
    },
    computed: {
        type() {
            return this.$store.state.solutionType
        }
    },
    watch: {
        type() {
            this.$nextTick(() => {
                document.getElementById('solution').scroll({
                    left: 0,
                    top: 0,
                    // behavior: 'smooth'
                })

            })
            
        
        }
    },
    data() {
        return {
            
        }
    },
    methods: {
        
    }
}

</script>
<style lang="scss" scoped>
	@import "@/assets/scss/variables.scss";
	@import "@/assets/scss/mixin.scss";
    .solution-page {
        color: white;
				padding-top: 1rem;
				height: calc(var(--vh) * 100);
				// background-color: red;
				overflow-y: hidden;
				
				#solution {
					width: 100%;
					height: 100%;
					overflow-y: scroll;
				}
    }
</style>
