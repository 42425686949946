<template>
    <div class="solution-human-page">
        <div class="header">
            <p class="title">中之人驱动虚拟人</p>
            <p class="desc">
                中之人驱动虚拟人主要用于泛娱乐领域实时直播使用，中之人驱动的优势在于实时交互性与真人一致，
                并且可以更准确驱动更高精度的模型，包括表情、肌肉、动作、唇形等。主要应用场景有抖音、快手、B站、视频号等平台的直播，
                以及通过ZD虚拟场景合成、全息或VR的方式实现演唱会、发布会等大型活动现场的深度交互。销售模式一般为一次性收费，
                完全由人物模型精度和资产数量决定，主要分为五大类：Live2D、Q版3D、二次元3D、美型写实和超写实
            </p>
            <ul class="item-list">
                <li v-for="h in headerList" :key="h.key">
                    <img :src="h.image"/>
                </li>
            </ul>
        </div>

        <div class="flow-box">
            <section-title title="制作流程"></section-title>
            <p class="desc">形象设计完成后，进行捕捉调试，支持数据驱动、摄像头驱动或动捕设备驱动，应用于直播、线下活动等场景实现实时深度交互。</p>
            <img class="flow-img" width="100%" src="@/assets/img/solution/human/process.png"/>
        </div>

        <div class="flow-box">
            <section-title title="方案架构"></section-title>
            <img class="flow-img" src="@/assets/img/solution/human/programme.png"/>
        </div>
         
        <div class="earth-box">
            <section-title title="方案优势"></section-title>
            <el-row :gutter="20">
                <el-col class="left-earth" :span="12">
                    <div class="left-earth-list" v-for="(e, index) in earthList" :key="index">
                        <img :src="e.icon"/>
                        <div class="right-box">
                            <p class="title">{{ e.title }}</p>
                            <p class="desc">{{ e.desc }}</p>
                        </div>
                    </div>
                </el-col>
                <el-col :span="12">
                    <img width="100%" src="@/assets/img/solution/human/earth.png"/>
                </el-col>
            </el-row>
        </div>
        
        <div class="buisenss-box">
            <section-title title="商业价值"></section-title>
            <el-row :gutter="20">
                <el-col :span="11">
                    <p class="title">建立品牌数字资产</p>
                    <p class="desc">企业专属虚拟IP，拥有量身定制的人设，内容受企业全程把控，同时虚拟代言人自带强大的热点基因有助于企业抢占元宇宙市场先机</p>
                </el-col>
                <el-col :offset="2" :span="11">
                    <p class="title">提升商业变现能力</p>
                    <p class="desc">让虚拟IP真正活起来，通过直播、主持、演出等企业营销场 景深度应用，使数字代言人和企业品牌的
                        影响力加成，有助 于提升企业知名度和产品销量
                    </p>
</el-col>
            </el-row>
        </div>
        <div class="scene-box">
            <section-title title="应用场景"></section-title>
            <ul class="item-list">
                <li v-for="h in sceneList" :key="h.key">
                    <img :src="h.image"/>
                </li>
            </ul>
        </div>
				<ContactInformation style="margin-top: 1.8rem"></ContactInformation>
				
    </div>
</template>
<script>
import sectionTitle from '../../components/section-title.vue'
export default {
  components: { sectionTitle },
    name: 'Human',
    data() {
        return {
            headerList: [
                {
                    key: 'header-1',
                    image: require("@/assets/img/solution/human/header-1.png"),
                },
                {
                    key: 'header-2',
                    image: require("@/assets/img/solution/human/header-2.png"),
                },
                {
                    key: 'header-3',
                    image: require("@/assets/img/solution/human/header-3.png"),
                },
                {
                    key: 'header-4',
                    image: require("@/assets/img/solution/human/header-4.png"),
                },
                {
                    key: 'header-5',
                    image: require("@/assets/img/solution/human/header-5.png"),
                }
            ],
            sceneList: [
                {
                    key: 'scene-1',
                    image: require("@/assets/img/solution/human/scene-1.png"),
                },
                {
                    key: 'scene-2',
                    image: require("@/assets/img/solution/human/scene-2.png"),
                },
                {
                    key: 'scene-3',
                    image: require("@/assets/img/solution/human/scene-3.png"),
                },
                {
                    key: 'scene-4',
                    image: require("@/assets/img/solution/human/scene-4.png"),
                },
                {
                    key: 'scene-5',
                    image: require("@/assets/img/solution/human/scene-5.png"),
                }
            ],
            earthList: [
                {
                    icon: require("@/assets/img/solution/realastic.png"),
                    title: '国内一流的形象设计及制作能力',
                    desc: '超强的美术制作实力，专业标准的制作流程，可高精度塑造人物 形态、表情、服装材质。支持角色设定、资产准备模型制作、 身体表情绑定、动画制作和渲染输出', 
                },
                {
                    icon: require("@/assets/img/solution/human/new_world.png"),
                    title: '高品质一站式服务',
                    desc: '动力学、面捕捕捉、动作捕捉、资产预设等高度集成，操作简单， 驱动精准，运行流畅', 
                },
                {
                    icon: require("@/assets/img/solution/human/dominance.png"),
                    title: '7X24小时贴心服务',
                    desc: '产品易用的同时提供完整的培训方案，保障用户流畅上手，7X24 小时待机，分钟级响应，保障用户业务流畅不中断', 
                }
            ]
        }
    },  
    methods: {



    }
}
</script>
<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";
@import "@/assets/scss/mixin.scss";
@mixin desc {
    font-size: 1rem;
    font-family: MicrosoftYaHeiLight;
    color: #FFFFFF;
    line-height: 2rem;
    letter-spacing: 2px;
    color: rgba($color: white, $alpha: 0.8);
}
// @mixin title {
//     color: #F5F5FF;
//     font-size: 1.5rem;
// }
@mixin item-list {
    display: flex;
    justify-content: space-between;
    li {
        width: 20%;
        padding: 0.16rem;
        img {
            width: 100%;
        }
    }
}
    .solution-human-page {
			height: 100%;
			overflow-y: scroll;
        .header {
            margin-top: 1.24rem;
            margin-left: $whole-lr;
            margin-right: $whole-lr;
            .title {
                @include font-2;
            }
            .desc {
                margin-top: 0.8rem;
                width: 50%;
                @include de-0-16;
            }
            .item-list {
                @include item-list;
                margin-top: 0.55rem;
                margin-right: 0.44rem;
            }
        }
        .flow-box {
            margin-top: 1.6rem;
            margin-left: $whole-lr;
            margin-right: $whole-lr;

            .desc {
                @include de-0-16;
                margin-top: 0.24rem;
            }

            img.flow-img {
                width: 100%;
                margin-top: 1.22rem;
            }
           
        }

        .earth-box {
            margin-top: 1.79rem;
            margin-left: $whole-lr;
            margin-right: $whole-lr;
            .el-row {
                background: url('../../assets/img/solution/human/section-bg.png') no-repeat;
                background-size: 100% 100%;  
                margin-top: 1.18rem;  
                height: 6rem;  
                display: flex;
                align-items:  center;   

                .left-earth {
                    padding-top: 1.2rem;
                    padding-bottom: 1.2rem;
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                .left-earth-list {
                    width: 100%;
                    margin-left: 1.22rem;
                    display: flex;
                    

                    .right-box {
                        margin-left: 0.32rem;
                        .title {
                            @include font-section;
                            font-size: 0.2rem;
                            font-weight: bold;
                            
                        }
                        .desc {
                            @include de-0-14;
                            margin-top: 0.12rem;
                        }
                    }
                    
                    >img {
                        width: 0.78rem;
                        margin-top: 0;
                    }
                }

            }

        }
    }

        .buisenss-box {
            margin-left: $whole-lr;
            margin-right:  $whole-lr;
            margin-top: 1.8rem;
            .el-row {
                margin-top: 1.2rem;

                .title {
                    @include font-section;
                    font-weight: bold;
                    
                }
                .desc {
                    @include de-0-16;
                    margin-top: 0.16rem;
                }
            }
        }

        .scene-box {
            margin-left: $whole-lr;
            margin-right:  $whole-lr;
            margin-top:  2rem;
            .item-list {
                @include item-list;
                margin-top: 0.99rem;
            }
        }

       
    }
    
</style>