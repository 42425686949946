<template>
    <div class="solution-broadcast-page" id="broadcast">
        <div class="header">
            <p class="title">AI 播报虚拟人</p>
            <img class="sub-title-line" src="@/assets/img/solution/title-sub-line.png"/>
            <p class="desc">
                依托爱希游自主研发的语音动画合成技术和人工智能驱动引擎，只需简单输入文字或音频，
                        即可快速生成具备精确口型、丰富表情和动作的虚拟主播播报视频。可广泛应用于媒体新闻、
                        教育课件、活动宣传、政策内容导读等视频生产和创意制作。 
            </p>
            <img class="line-img" src="@/assets/img/solution/desc-sub-line.png"/>
            <img class="header-right-img" src="@/assets/img/solution/broadcast/header-right.png"/>
        </div>
        
        <div class="section-box" style="margin-top: 4.86rem;">
            <section-title title="技术原理"></section-title>
            <img class="content-img" style="margin-top: 0.33rem;" src="@/assets/img/solution/broadcast/technolegy.png"/>
        </div>
        <div class="section-box">
            <section-title title="方案架构"></section-title>
            <img class="content-img" style="margin-top: 0.99rem;" src="@/assets/img/solution/broadcast/fajg.png"/>
        </div>

        <div class="section-box">
            <section-title title="方案优势"></section-title>
            <div class="top-box">
                <div class="top-list" v-for="(e, index) in topList" :key="index">
                    <img :src="e.icon"/>
                    <div class="content-box">
                        <p class="title">{{ e.title }}</p>
                        <p class="desc">{{ e.desc }}</p>
                    </div>
                </div>
            </div>
        </div>

        <div class="section-box">
            <section-title title="商业价值"></section-title>
            <div class="line"></div>
            <el-row class="business-box" :gutter="20">
                <el-col :span="14" class="left-box">
                    <div class="left-item" v-for="(e, index) in businessList" :key="index">
                        <p class="title">{{ e.title }}</p>
                        <p class="desc">{{ e.desc }}</p>
                    </div>
                </el-col>
                <el-col :span="10">
                    <img class="broadcast-right" src="@/assets/img/solution/broadcast/broadcast-right.png"/>
                </el-col>
            </el-row>
        </div>
        <div class="section-box se-mg-t">
            <section-title title="应用场景"></section-title>
            <ul class="item-list se-content-t">
                <li v-for="h in sceneList" :key="h.key">
                    <img :src="h.image"/>
                </li>
            </ul>
        </div>
				<ContactInformation style="margin-top: 1.8rem"></ContactInformation>
				
    </div>
</template>
<script>
export default {
    name: 'Broadcast',
    data() {
        return {
            sceneList: [
                {
                    key: 'broadcast-scene-1',
                    image: require("@/assets/img/solution/broadcast/broadcast-scene-1.png"),
                },
                {
                    key: 'broadcast-scene-2',
                    image: require("@/assets/img/solution/broadcast/broadcast-scene-2.png"),
                },
                {
                    key: 'broadcast-scene-3',
                    image: require("@/assets/img/solution/broadcast/broadcast-scene-3.png"),
                },
                {
                    key: 'broadcast-scene-4',
                    image: require("@/assets/img/solution/broadcast/broadcast-scene-4.png"),
                },
                {
                    key: 'broadcast-scene-5',
                    image: require("@/assets/img/solution/broadcast/broadcast-scene-5.png"),
                }
            ],
            businessList: [
                {
                    title: '高质量内容生成系统，助力视频内容创新',
                    desc: '语音动画合成技术和人工智能技术业界领先，虚拟人播报媲美真人，助力企业打造个性化的视频营销内容', 
                },
                {
                    title: '一键生成，提升内容生产效率',
                    desc: '无需场地、无需人员，即可简单快捷的制作视频，保障播报实时性、零失误，有效降低制作成本，缩短视频制作周期，提高制播效率', 
                },
            ],
            topList: [
                {
                    icon: require("@/assets/img/solution/realastic.png"),
                    title: '种类丰富的虚拟形象',
                    desc: '不同风格的高质量虚拟形象模板免费提供，同时提供满足不同场景需求的形象定制服务', 
                },
                {
                    icon: require("@/assets/img/solution/realastic.png"),
                    title: '生动逼真的动作形态',
                    desc: '采用领先的语音动画合成技术和智能表情算法，形成唇音同步、表情姿态生动的虚拟主播', 
                },
                {
                    icon: require("@/assets/img/solution/realastic.png"),
                    title: '简易快捷的操作方式',
                    desc: 'Web后台即可快速操作，输入播报内容，一键生成视频。零基础也可快速生产专业播报视频'
                },
                {
                    icon: require("@/assets/img/solution/realastic.png"),
                    title: '自然流畅的语音效果',
                    desc: '支持文字驱动和语音驱动两种模式。对接多个精品音色，支持语速和音量调整，支持文本试听支持多音字读音修改'
                },
                {
                    icon: require("@/assets/img/solution/realastic.png"),
                    title: '功能实用的处理能力',
                    desc: '支持单人/多人主播切换，支持画面横竖比例切换， 支持图片和视频背景，支持图片和视频画中画支持添加字幕', 
                },
                {
                    icon: require("@/assets/img/solution/realastic.png"),
                    title: '灵活高效的合作方式',
                    desc: '根据不同需求，提供SAAS版，渠道版，私有化部署等多种合作方式，帮助客户快速上线使用', 
                }
            ]
        }
    },
		mounted() {
			// console.log("mounted");
			// this.$nextTick(() => {
			//     document.getElementById('broadcast').scroll({
			//         left: 0,
			//         top: 0,
			//         // behavior: 'smooth'
			//     })
			
			// })
		},
		created() {
			
			
		}
}
</script>
<!-- <style lang="scss" scoped>
@import "@/assets/scss/solution/intellect.scss";

.broadcast-right{
    height: 100%;
}
</style> -->

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";
@import "@/assets/scss/mixin.scss";
@mixin item-list {
    display: flex;
    justify-content: space-between;
    li {
        width: 2.8rem;
        img {
            width: 100%;
        }
    }
}

.line {
    width: 100%;
    height: 0.18rem;
    margin-top: 0.53rem;
    background: url(../../assets/img/solution/line-bg.png) no-repeat;
    background-size: 100% 100%;
}

    .solution-broadcast-page {
        .header {
            position: relative;
            margin-top: 1.24rem;
            margin-left: $whole-lr;
            .title {
                @include font-2;
            }
            .sub-title-line {
                height: 0.22rem;
                margin-top: 0.15rem;
            }
            .desc {
                margin-top: 0.49rem;
                width: 47%;
                @include de-0-16;
            }
            .line-img {
                width: 47%;
                height: 0.87rem;
                margin-top: 0.54rem;
            }
            .header-right-img {
                position: absolute;
                width: 5.28rem;
                height: 6.08rem;
                right: 1.8rem;
                top: 1.4rem;

            }
        }
        .section-box {
            position: relative;
            margin-left: $whole-lr;
            margin-right: $whole-lr;
            margin-top: 1.8rem;

            .content-img {
                width: 100%;
            }
            .icon-box {
                text-align: center;
                .user-img {
                    width: 5.28rem;
                    height: 6.09rem;
                }
            }
            .top-box {
                width: 100%;
                margin-top: 1rem;
                display: flex;
                flex-wrap: wrap;

                .top-list {
                    width: 50%;
                    margin-bottom: 0.67rem;
                    display: flex;

                    .content-box {
                        margin-left: 0.32rem;
                        .title {
                            @include font-section;
                            font-weight: bold;
                        }
                        .desc {
                            @include de-0-14;
                            margin-top: 0.12rem;
                            width: 90%;
                        }
                    }
                    
                    >img {
                        width: 0.78rem;
                        margin-top: 0;
                    }
                }

            }

            .business-box {
                margin-top: 1.08rem;
                display: flex;
                align-items: center;
                .left-box {
                    .left-item {
                        margin-bottom: 0.54rem;
                        .title {
                            @include font-section;
                            font-weight: bold;
                        }
                        .desc {
                            width: 90%;
                            margin-top: 0.16rem;
                            @include de-0-16;
                        }

                    }
                }

                img {
                        width: 100%;
                    }

            }
            .item-list {
                margin-top: 0.83rem;
                @include item-list;

            }

            
        }
    }
</style>

